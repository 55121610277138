import { useAuthenticator, Link, View, Text } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";

export const SignInFooter = () => {
  const { toForgotPassword } = useAuthenticator();

  return (
    <View className="sign-in-footer">
      <Text>
        {I18n.get('Forgotten password')}
        <Link
          onClick={toForgotPassword}
          className="link pl-10"
        >
          {I18n.get('Reset Password')}
        </Link>
      </Text>
    </View>
  );
}
