import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthContextProvider } from "./contexts/AuthContext";
import App from './App';

import "./App.css";
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
</React.StrictMode>);