import React, { useContext, useEffect } from "react";
import { useAuthenticator, View } from "@aws-amplify/ui-react";

import { AuthContext } from "./../contexts/AuthContext";

export const Header = () => {
  const { setAuthState } = useContext(AuthContext);
  const { route } = useAuthenticator();

  useEffect(() => {
    setAuthState(route)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  return (
    <View className="logo">
      <svg width="42" height="64" viewBox="0 0 42 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6086 20.4302L7.55884 18.6699V39.7603L21.9885 48.0898V44.5601L10.6086 37.9902V20.4302Z" fill="#448CF6"/>
        <path d="M18.1299 19.1802L0.0197754 8.72021V48.4802L26.8599 64.0002V34.3302L18.0898 29.2702L18.1299 19.1802ZM23.8499 58.7202L3.06982 46.7202V14.0002L15.0698 20.9402V31.0002L23.8298 36.0602L23.8499 58.7202Z" fill="#2158DC"/>
        <path d="M15.0901 0V6.57031L18.1399 8.33008V5.28027L38.9202 17.2803V41.27L36.28 39.7402V43.2603L41.9802 46.5503V15.52L15.0901 0Z" fill="#9DCBFC"/>
        <path d="M7.55884 10.9302L10.6086 12.7002V9.64014L31.3689 21.6299V49.98L28.7385 48.46V51.9902L34.4387 55.2803V19.8799L7.55884 4.35986V10.9302Z" fill="#448CF6"/>
        </svg>
    </View>
  );
}