import React from "react";
import { View, Text } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";

export const SignUpFooter = () => {
  const [ marketingBool, setMarketingBool ] = React.useState(false);

  React.useEffect(() => {
    const checkState =  localStorage.getItem('marketingBool');
    if(checkState !== null) {
      setMarketingBool(JSON.parse(checkState))
    }
  }, [])

  return (
    <View className="sign-up-footer">
      <div className="relative flex items-start flex-row justify-start">
        <div className="flex items-center h-5">
          <input
            id="marketing"
            aria-describedby="marketing-description"
            name="marketing"
            type="checkbox"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={marketingBool}
            onChange={(e) => {
              setMarketingBool(!marketingBool)
              localStorage.setItem('marketingBool', JSON.stringify(e.target.checked));
            }}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="marketing">
           {I18n.get('Click to opt out of more information from Legalesign.')}
          </label>
        </div>
      </div>
      <Text>
      {I18n.get('By clicking')} <span style={{fontWeight: 'bold'}}>{I18n.get('Create Account')}</span> {I18n.get('you agree to our')}<br /> <a href="https://legalesign.com/opn/terms/" className="link" target="_blank" rel="noreferrer noopener">{I18n.get('Terms of Service')}</a> {I18n.get('and')} <a href="https://legalesign.com/opn/privacy/" className="link" target="_blank" rel="noreferrer noopener">{I18n.get('Privacy Policy')}</a>.
      </Text>
      <br />
      <Text>{I18n.get('Data Location')}: <span style={{fontWeight: 'bold'}}>{I18n.get('London, UK')}</span></Text>
    </View>
  );
}
