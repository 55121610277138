import { useAuthenticator, Link, View, Text, Heading } from "@aws-amplify/ui-react";
import { I18n} from "aws-amplify/utils";

export const SignUpHeader = () => {
  const { toSignIn } = useAuthenticator();

  return (
    <View className="sign-up-header">
      <Heading level={1}>{I18n.get("Start Your free trial")}</Heading>
      <Text>{I18n.get("Already have an account?")}
        <Link
          onClick={toSignIn}
          className="link pl-10"
        >
          {I18n.get("Log in")}
        </Link>
      </Text>
    </View>
  );
}