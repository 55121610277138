import React, { useContext } from "react";
import 'aws-amplify/auth/enable-oauth-listener';
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
import { signInWithRedirect, signOut, signUp, confirmSignUp, autoSignIn } from "aws-amplify/auth";
import { translations } from '@aws-amplify/ui';
import { Authenticator } from "@aws-amplify/ui-react";

import { Header } from "./components/Header";
import { SignInHeader } from "./components/SignInHeader";
import { SignUpHeader } from "./components/SignUpHeader";
import { SignInFooter } from "./components/SignInFooter";
import { SignUpFooter } from "./components/SignUpFooter";
import { AuthContext } from "./contexts/AuthContext";
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabularies({
  en: {
    "Sign in": "Login", // Button label
    "Sign In with Google": "Login with Google",
    "Confirm": "Submit",
    "Reset your password": "Forgotten your password",
    "Forgotten password": "Forgotten password",
    "Reset Password": "Reset Password",
    "Legalesign Login": "Legalesign Login",
    "Don't have an account?": "Don't have an account?",
    "Sign up": "Sign up",
    "Start Your free trial": "Start Your free trial",
    "Already have an account?": "Already have an account?",
    "Log in": "Log in",
    "I agree to join Legalesign’s mailing list (you can unsubscribe at anytime).": "I agree to join Legalesign’s mailing list (you can unsubscribe at anytime).",
    "Data Location": "Data Location",
    "London, UK": "London, UK",
    "By clicking": "By clicking",
    "Create Account": "Create Account",
    "you agree to our": "you agree to our",
    "Terms of Service": "Terms of Service",
    "and": "and",
    "Privacy Policy": "Privacy Policy",
    "Reassuring Data Security": "Reassuring Data Security",
    "ISO27001 and Cyber Essentials Plus Certified.": "ISO27001 and Cyber Essentials Plus Certified.",
    "Plans for All Organisations": "Plans for All Organisations",
    "14 day free trial with plans to choose starting at £6+VAT/month.": "14 day free trial with plans to choose starting at £6+VAT/month.",
    "API Integrations": "API Integrations",
    "Integrate with leading software platforms to automate your business workflow.": "Integrate with leading software platforms to automate your business workflow.",
    "Trusted Across the UK": "Trusted Across the UK",
    "Start adding to the millions of documents sent, signed and ewitnessed by Legalesign.": "Start adding to the millions of documents sent, signed and ewitnessed by Legalesign.",
  },
});


// Amplify.configure({
//   Auth: {
//     cookieStorage: {
//       domain: process.env.REACT_APP_cookie_domain,
//       secure: !(process.env.NODE_ENV === "development"),
//       path: "/",
//       expires: 365,
//     },
//     Cognito: {
//       //  Amazon Cognito User Pool ID
//       userPoolId:  process.env.REACT_APP_user_pools_id,
//       // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//       userPoolClientId: process.env.REACT_APP_user_pools_web_client_id,
//       // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//       identityPoolId: process.env.REACT_APP_cognito_identity_pool_id,
//       // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
//       // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
//       signUpVerificationMethod: 'code', // 'code' | 'link'
//       loginWith: {
//         // OPTIONAL - Hosted UI configuration
//         oauth: {
//           domain: process.env.REACT_APP_oauth_domain,
//           scopes: ["aws.cognito.signin.user.admin", "openid", "phone", "profile", "email"],
//           redirectSignIn: [process.env.REACT_APP_redirect_sign_in],
//           redirectSignOut: [process.env.REACT_APP_redirect_sign_out],
//           responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
//         }
//       }
//     }
//   },
// });

Amplify.configure({
  aws_project_region: process.env.REACT_APP_cognito_region,
  aws_cognito_identity_pool_id: process.env.REACT_APP_cognito_identity_pool_id,
  aws_cognito_region: process.env.REACT_APP_cognito_region,
  aws_user_pools_id: process.env.REACT_APP_user_pools_id,
  aws_user_pools_web_client_id: process.env.REACT_APP_user_pools_web_client_id,
  oauth: {
    domain: process.env.REACT_APP_oauth_domain,
    scope: ["aws.cognito.signin.user.admin", "openid", "phone", "profile", "email"],
    redirectSignIn: process.env.REACT_APP_redirect_sign_in,
    redirectSignOut: process.env.REACT_APP_redirect_sign_out,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({domain: process.env.REACT_APP_cookie_domain }));


const App = () => {
  const [ initialState, setInitialState ] = React.useState('signIn');
  const { authState } = useContext(AuthContext);

  const handleSignOut = async () => {
    try {
      await signOut()
    }
    catch(error) {
      console.error(error)
    }
  }

  const handleInitialState = async (query) => {
    const q = query.get('q')
    const s = query.get('s')
    if (q === 'signUp' && !s) {
      window.location.href = 'https://legalesign.com/landing/free-trial/';
    }
    console.log(q)
    try {
      const signout = handleSignOut()
      signout.then(
        () => {
          if (['signIn', 'signUp', 'resetPassword'].includes(q)) {
              setInitialState(q)
          } else {
            setInitialState('signIn')
          }
        }
      )
    } catch(error) {
      console.error(error)

      setInitialState('signIn')
    }
  }

  const validateDomainInStr = (str) => {
    let url
    try {
      url = new URL(str);
    } catch(error) {
    }
    if (url && url.origin.includes('legalesign')) {
      return true
    }
    return false
  }

  const handlePostLoginRedirect = () => {
    const query = new URLSearchParams(window.location.search);
    const redirectURI = query.get('redirect_uri')
    let redirectURIStr
    if (redirectURI) {
      redirectURIStr = redirectURI.toString()
    }
    let returnUrl;

    if (redirectURIStr && validateDomainInStr(redirectURIStr)) {
      returnUrl = redirectURIStr !== undefined
        ? `${process.env.REACT_APP_postlogin_url}?redirect_uri=${redirectURIStr}`
        : process.env.REACT_APP_postlogin_url
    } else {
      returnUrl = process.env.REACT_APP_postlogin_url
    }
    returnUrl && window.location.assign(returnUrl)
  }

  const handleIDP = async (query) => {
    if ( query.match(/^[_-\w]{3,30}$/) ) {
      try {
        signInWithRedirect({provider: {custom:query}})
      } catch(error) {
      }
     }
  }
  const deleteAllCookies = () => {
    var cookies = document.cookie.split("; ").filter((c) => !c.startsWith('welcome'));
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            // eslint-disable-next-line no-restricted-globals
            var p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }
}

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const idp = searchParams.get('idp')
    if (idp) {
      handleIDP(idp)
    } else {
      deleteAllCookies();
      handleInitialState(searchParams);     
    }  
  }, [])

  React.useEffect(() => {
    if (authState) {
      setTimeout(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const username = searchParams.get('Username')
        const input = document.querySelector('input[name="email"]') || document.querySelector('input[name="username"]');
        if (input && username) input.setAttribute("value", username);
      });
    }
  }, [authState]);

  const components = {
    Header: Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    SignUp: {
      Header: SignUpHeader,
      Footer: () => <SignUpFooter />
    }
  };
  
  const services = {
    async handleSignUp(formData) {
      let { username, password } = formData;
      // The checkbox askes the user to check / TRUE to avoid the marketing emails
      const dontReceiveMarketing = localStorage.getItem('marketingBool');

      const outResult = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email: username,
            "custom:licence_type": "licence-spring", 
            "custom:marketing": (dontReceiveMarketing==='true') ? 'false': 'true'
          },
          autoSignIn: true
        }
      });

      return outResult;
    },
    async handleConfirmSignUp ({
      username,
      confirmationCode
    }) {
      const results = await confirmSignUp({
        username,
        confirmationCode
      });

      return results;
    },
    async handleAutoSignIn() {
      try {
        const signInOutput = await autoSignIn();
        
        // handle sign-in steps
        handlePostLoginRedirect()
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {initialState &&
        <>
        <section className="container">
          {authState === "signUp" &&
            <div className="left-half m_h">
              <div style={{margin: '0 80px'}}>
                <div className="left-heading">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10.5L9 12.5L13 8.5M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z" stroke="#33B8A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                  <h2>{I18n.get('Reassuring Data Security')}</h2>
                  <p>{I18n.get('ISO27001 and Cyber Essentials Plus Certified.')}</p>
                </div>

                <div className="left-heading">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10.5L9 12.5L13 8.5M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z" stroke="#33B8A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>

                  <h2>{I18n.get('Plans for All Organisations')}</h2>
                  <p>{I18n.get('14 day free trial with plans to choose starting at £6+VAT/month.')}</p>
                </div>

                <div className="left-heading">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10.5L9 12.5L13 8.5M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z" stroke="#33B8A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                  <h2>{I18n.get('API Integrations')}</h2>
                  <p>{I18n.get('Integrate with leading software platforms to automate your business workflow.')}</p>
                </div>

                <div className="left-heading">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10.5L9 12.5L13 8.5M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z" stroke="#33B8A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                  <h2>{I18n.get('Trusted Across the UK')}</h2>
                  <p>{I18n.get('Start adding to the millions of documents sent, signed and ewitnessed by Legalesign.')}</p>
                </div>
              </div>
            </div>
          }
          {authState === "signIn" &&
            <div className="left-half m_h">
              <div style={{margin: '0 80px'}}>
                <div className="left-heading">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10.5L9 12.5L13 8.5M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z" stroke="#33B8A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                  <h2>{I18n.get('eWitness')}</h2>
                  <p>{I18n.get('Try Legalesign remote or device-locked eWitnessing.')}</p>
                </div>

                <div className="left-heading">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10.5L9 12.5L13 8.5M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z" stroke="#33B8A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>

                  <h2>{I18n.get('Tablet App')}</h2>
                  <p>{I18n.get('For any in-person signing, fields sales or in the office - works offline too.')}</p>
                </div>

                <div className="left-heading">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10.5L9 12.5L13 8.5M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z" stroke="#33B8A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                  <h2>{I18n.get('API & Webhooks')}</h2>
                  <p>{I18n.get('Link Legalesign to other software.')}</p>
                </div>

                <div className="left-heading">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10.5L9 12.5L13 8.5M19 10.5C19 15.4706 14.9706 19.5 10 19.5C5.02944 19.5 1 15.4706 1 10.5C1 5.52944 5.02944 1.5 10 1.5C14.9706 1.5 19 5.52944 19 10.5Z" stroke="#33B8A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                  <h2>{I18n.get('Salesforce')}</h2>
                  <p>{I18n.get('Contact us to get the integration.')}</p>
                </div>
              </div>
            </div>
          }
          <div className="right-half">
            <Authenticator
              services={services}
              initialState={new URLSearchParams(window.location.search).get('q') ? new URLSearchParams(window.location.search).get('q') : "signIn"}
              signUpAttributes={["email"]}
              socialProviders={["google"]}
              components={components}
              loginMechanisms={["email"]}
            >
              {() => (
                <>
                  {handlePostLoginRedirect()}
                </>
              )}
            </Authenticator>
          </div>
        </section>
        </>
      }
    </>
  );
};

export default App;
