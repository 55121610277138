import { Link, View, Text, Heading, useAuthenticator } from "@aws-amplify/ui-react";
import { I18n} from "aws-amplify/utils";

export function SignInHeader() {
  // const toSignUp = () => {
  //   window.location.href = 'https://legalesign.com/landing/free-trial/';
  // }
  const { toSignUp } = useAuthenticator();
  return (
    <View className="sign-in-header">
      <Heading level={1}>{I18n.get('Legalesign Login')}</Heading>
      <Text>{I18n.get("Don't have an account?")}
        <Link
          onClick={toSignUp}
          className="link pl-10"
        >
          {I18n.get('Sign up')}
        </Link>
      </Text>
    </View>
  );
}